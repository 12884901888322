import { useQuery } from 'react-query'
import { TemplateEditModalForm } from './TemplateEditModalForm'
import { isNotEmpty, QUERIES } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { getTemplateById } from '../core/_requests'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const TemplateEditModalFormWrapper = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: template,
    error,
  } = useQuery(
    `${QUERIES.TEMPLATES_LIST}-template-${itemIdForUpdate}`,
    () => {
      return getTemplateById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  // if (!itemIdForUpdate) {

  //   return <TemplateEditModalForm isTemplateLoading={isLoading} template={{id: undefined}} />
  // }

  if (!isLoading && !error && template) {
    return <DndProvider backend={HTML5Backend}>
      <TemplateEditModalForm isTemplateLoading={isLoading} template={template} />
    </DndProvider>
  }

  return null
}

export { TemplateEditModalFormWrapper }
