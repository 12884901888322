import { FC, useState, useMemo, useEffect, useRef } from 'react';
import { AddPhotoAlternate } from "@mui/icons-material";
import { Box, Modal } from "@mui/material";
import clsx from 'clsx';
import { useDrag, useDrop } from 'react-dnd';
import { useFormik } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import Cropper from "react-easy-crop";
import CreatableSelect from 'react-select/creatable';

import { getEmptyImage } from 'react-dnd-html5-backend';
import * as Yup from 'yup';
import { KTSVG, isNotEmpty, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { RadioInput, convertArrToSelect, dateDownloadFormat, downloadFile } from "../../../../../../jsFunctions";
import { TemplatesListLoading } from '../components/loading/TemplatesListLoading';
import { useListView } from '../core/ListViewProvider';
import { useQueryResponse } from '../core/QueryResponseProvider';
import { Template, initialTemplate } from '../core/_models';
import { updateTemplate } from '../core/_requests';

import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";


interface DragItem {
  index: number;
}

interface DragCollectedProps {
  isDragging: boolean;
}

type Props = {
  isTemplateLoading: boolean
  template: Template
}

const editTemplateSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})


interface DropdownOptions {
  readonly value: string;
  readonly label: string;
}

const dropdownOptions: readonly DropdownOptions[] = [
  { value: 'template', label: 'template' },
  { value: 'client', label: 'client' },
  { value: 'testing', label: 'testing' },
  { value: 'SAYCHEESE', label: 'SAYCHEESE' },
];

const ReorderableImage = ({
  face,
  index,
  reorderImages,
  removeImage,
  replaceImage,
  setCropModalOpen,
  setCurrentImage,
  setCurrentIndex,
}: {
  face: string;
  index: number;
  reorderImages: (dragIndex: number, dropIndex: number) => void;
  removeImage: (index: number) => void;
  replaceImage: (index: number, file: File) => void;
  setCropModalOpen: (open: boolean) => void;
  setCurrentImage: (image: string | null) => void;
  setCurrentIndex: (index: number | null) => void;
}) => {
  const dragItemRef = useRef<HTMLDivElement | null>(null);

  const [{ isDragging }, drag, preview] = useDrag({
    type: "image",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "image",
    drop: (item: { index: number }) => {
      const dragIndex = item.index;
      const dropIndex = index;

      if (dragIndex !== dropIndex) {
        reorderImages(dragIndex, dropIndex);
      }
    },
  });

  useEffect(() => {
    if (dragItemRef.current) {
      preview(dragItemRef.current, { captureDraggingState: true });
    }
  }, [preview]);

  const handleReupload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      replaceImage(index, file);
      const reader = new FileReader();
      reader.onload = () => {
        setCurrentImage(reader.result as string); // Open the crop modal with the newly uploaded image
        setCropModalOpen(true);
        setCurrentIndex(index); // Track the index for replacement
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div
      ref={(node) => {
        drag(drop(node));
        dragItemRef.current = node;
      }}
      style={{
        position: "relative",
        width: "150px",
        height: "225px",
        borderRadius: "5px",
        overflow: "hidden",
        boxShadow: "0 1px 11px rgba(0, 0, 0, 0.1)",
        opacity: isDragging ? 0.5 : 1,
        transition: "opacity 0.2s ease",
        cursor: "move",
      }}
    >
      <img
        src={face}
        alt={`Uploaded ${index}`}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        onClick={() => document.getElementById(`reupload-input-${index}`)?.click()} // Trigger reupload input click
      />
      <input
        type="file"
        accept="image/*"
        id={`reupload-input-${index}`}
        style={{ display: "none" }}
        onChange={handleReupload}
      />
      <div
        onClick={() => removeImage(index)}
        className="svg-icon-2 text-danger btn-danger"
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          width: "40px",
          height: "40px",
          backgroundColor: "rgba(255, 255, 255, 0.75)",
          borderRadius: "10%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
        }}
        data-bs-toggle="tooltip"
        title="Delete Image"
      >
        <KTSVG
          path="/media/icons/duotune/general/gen027.svg"
          className="svg-icon-2 text-danger"
        />
      </div>
    </div>
  );
};

const TemplateEditModalForm: FC<Props> = ({ template, isTemplateLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  // alert(template.disable + " " + template.dooh)

  const [templateForEdit] = useState<Template>({
    ...template,
    avatar: template.avatar || initialTemplate.avatar,
    dooh: template.dooh || initialTemplate.dooh,
    uploadAllowed: template.uploadAllowed || initialTemplate.uploadAllowed,
    microsite: template.microsite || initialTemplate.microsite,
    info: template.info || initialTemplate.info,
    name: template.name || initialTemplate.name,
    tags: template.tags ? convertArrToSelect(template.tags) : dropdownOptions,
    faces: template.faces || [], // Ensure faces are initialized here
    facesFiles: template.facesFiles || [], // Ensure facesFiles are initialized here
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

  const [avatar, setAvatar] = useState<any>(templateForEdit.avatar);

  // const handleChange = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ): Promise<any> => {

  //   const ImageName = event.target.value.split('\\')[2];
  //   const Image = event.target.value;
  //   console.log('Imageon Kevel: ', Image); // Image on Kevel
  //   console.log('ImageName on Kevel: ', ImageName); // ImageName on Kevel
  //   console.log('ImageLink on Kevel: ', event.target.value); // ImageLink on Kevel

  //   if (event?.target?.files?.[0]!) {
  //     setAvatar({ display: URL.createObjectURL(event?.target?.files?.[0]), uploadAllowed: event?.target?.files?.[0] });
  //   }
  // };

  const formik = useFormik({
    initialValues: templateForEdit,
    validationSchema: editTemplateSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateTemplate({ ...values })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const handleMicrositeChange = (newValue: boolean) => {
    formik.setFieldValue('microsite', newValue);
    if (newValue) {
      formik.setFieldValue('uploadAllowed', true);
    }
  };

  const handleDOOHChange = (newValue: boolean) => {
    formik.setFieldValue('dooh', newValue);
    if (newValue) {
      formik.setFieldValue('uploadAllowed', true);
    }
  };

  const handleUploadChange = (newValue: boolean) => {
    if (newValue) {
      formik.setFieldValue('uploadAllowed', newValue);
    }
    else if (!formik.values.microsite && !formik.values.dooh) {
      formik.setFieldValue('uploadAllowed', newValue);
    }
  };

  // const [currentImage, setCurrentImage] = useState<string | null>(null);
  // const [cropModalOpen, setCropModalOpen] = useState(false);
  // const [cropConfig, setCropConfig] = useState({ x: 0, y: 0 });
  // const [zoom, setZoom] = useState(1);
  // const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

  // const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //     const imageURL = URL.createObjectURL(file);
  //     setCurrentImage(imageURL);
  //     setCropModalOpen(true);
  //   }
  // };

  // const onCropComplete = (_: any, croppedAreaPixels: any) => {
  //   setCroppedAreaPixels(croppedAreaPixels);
  // };

  // const confirmCrop = async () => {
  //   if (!currentImage || !croppedAreaPixels) return;

  //   const croppedImage = await getCroppedImage(currentImage, croppedAreaPixels);

  //   // Add the cropped image (base64 data URL) to faces array (for display in the UI)
  //   formik.setFieldValue("faces", [...formik.values.faces || [], croppedImage]);

  //   // Convert base64 string to File object before adding to facesFiles
  //   const file = base64ToFile(croppedImage);

  //   // Add the File object to facesFiles (for upload later)
  //   formik.setFieldValue("facesFiles", [
  //     ...(formik.values.facesFiles || []),
  //     file, // Store the File object here
  //   ]);

  //   setCropModalOpen(false);
  //   setCurrentImage(null);
  // };

  // // Helper function to convert base64 to a File object
  // const base64ToFile = (base64: string): File => {
  //   const byteString = atob(base64.split(',')[1]); // Extract the base64 string (remove the "data:image/jpeg;base64," part)
  //   const arrayBuffer = new ArrayBuffer(byteString.length);
  //   const uintArray = new Uint8Array(arrayBuffer);

  //   // Convert the byte string into an array buffer
  //   for (let i = 0; i < byteString.length; i++) {
  //     uintArray[i] = byteString.charCodeAt(i);
  //   }

  //   // Create a File object with the array buffer
  //   const blob = new Blob([uintArray], { type: 'image/jpeg' }); // Change MIME type if necessary
  //   return new File([blob], 'cropped_image.jpg', { type: 'image/jpeg' }); // Modify the filename if needed
  // };

  // const removeImage = (index: number) => {
  //   if (formik.values.faces) {
  //     const updatedFaces = formik.values.faces.filter((_, i) => i !== index);
  //     formik.setFieldValue("faces", updatedFaces);
  //   }
  // };

  // const getCroppedImage = (imageSrc: string, croppedAreaPixels: any) => {
  //   return new Promise<string>((resolve) => {
  //     const img = new Image();
  //     img.src = imageSrc;
  //     img.onload = () => {
  //       const canvas = document.createElement("canvas");
  //       const ctx = canvas.getContext("2d");

  //       if (!ctx) return;

  //       canvas.width = croppedAreaPixels.width;
  //       canvas.height = croppedAreaPixels.height;

  //       ctx.drawImage(
  //         img,
  //         croppedAreaPixels.x,
  //         croppedAreaPixels.y,
  //         croppedAreaPixels.width,
  //         croppedAreaPixels.height,
  //         0,
  //         0,
  //         croppedAreaPixels.width,
  //         croppedAreaPixels.height
  //       );

  //       resolve(canvas.toDataURL("image/jpeg"));
  //     };
  //   });
  // };

  const removeImage = (index: number) => {
    const updatedFaces = [...(formik.values.faces || [])];
    updatedFaces.splice(index, 1);
    formik.setFieldValue('faces', updatedFaces);
  };

  const replaceImage = (index: number, file: File) => {
    const updatedFaces = [...(formik.values.faces || [])];
    const imageURL = URL.createObjectURL(file);
    updatedFaces[index] = imageURL; // Replace the image URL
    formik.setFieldValue('faces', updatedFaces);

    const updatedFiles = [...(formik.values.facesFiles || [])];
    updatedFiles[index] = file; // Replace the actual file
    formik.setFieldValue('facesFiles', updatedFiles);
  };

  const [currentImage, setCurrentImage] = useState<string | null>(null);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [cropConfig, setCropConfig] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null); // Add this line to keep track of the current image's index

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setCurrentImage(imageURL);
      setCropModalOpen(true);
    }
  };

  const onCropComplete = (_: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const confirmCrop = async () => {
    if (!currentImage || !croppedAreaPixels || currentIndex === null) return;
  
    const croppedImage = await getCroppedImage(currentImage, croppedAreaPixels);
  
    // Safely access `formik.values.faces` as an array
    const updatedFaces = [...(formik.values.faces || [])];
    updatedFaces[currentIndex] = croppedImage;
  
    const updatedFiles = [...(formik.values.facesFiles || [])];
    updatedFiles[currentIndex] = base64ToFile(croppedImage);
  
    formik.setFieldValue("faces", updatedFaces);
    formik.setFieldValue("facesFiles", updatedFiles);
  
    setCropModalOpen(false);
    setCurrentImage(null);
    setCurrentIndex(null);
  };

  const getCroppedImage = (imageSrc: string, croppedAreaPixels: any) => {
    return new Promise<string>((resolve) => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        ctx.drawImage(
          img,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );

        resolve(canvas.toDataURL("image/jpeg"));
      };
    });
  };

  const base64ToFile = (base64: string): File => {
    const byteString = atob(base64.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([uintArray], { type: 'image/jpeg' });
    return new File([blob], 'cropped_image.jpg', { type: 'image/jpeg' });
  };

  const reorderImages = (dragIndex: number, dropIndex: number) => {
    const updatedFaces = [...(formik.values.faces || [])]; // Create a copy of the faces array
    const [removed] = updatedFaces.splice(dragIndex, 1);   // Remove the dragged item
    updatedFaces.splice(dropIndex, 0, removed);           // Insert it at the drop index
    formik.setFieldValue('faces', updatedFaces);          // Update the formik state
  };

  return (
    <>
      <form id='kt_modal_add_template_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_template_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_template_header'
          data-kt-scroll-wrappers='#kt_modal_add_template_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='d-block fw-bold fs-6 mb-5'>Template Cover</label>
            {/* end::Label */}

            {/* begin::Image input */}
            <div
              // className='image-input image-input-outline'
              className='image-input'
              data-kt-image-input='true'
              style={{ backgroundImage: `url('${blankImg}')` }}
            >
              {/* begin::Preview existing avatar */}


              {templateForEdit.video ? (
                <>
                  <video
                    className='content-input-wrapper'
                    src={templateForEdit.video}
                    controls
                    controlsList="nodownload"
                    style={{
                      width: '225px',
                      height: 'auto',
                      objectFit: 'contain',
                      borderRadius: '10px 10px 10px 10px',
                    }}
                  />
                  <a
                    onClick={() => {
                      downloadFile(
                        templateForEdit.video,
                        dateDownloadFormat(templateForEdit.name) +
                        ".mp4",
                        true
                      )
                    }}
                    download={`${templateForEdit.id}.mp4`}
                    className='btn btn-icon btn-sm btn-bg-white btn-active-color-primary position-absolute top-0 end-0 m-3 mt-5'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Download Video'
                  >
                    <i className='bi bi-download'></i>
                  </a>
                </>
              ) : (
                <>
                  <img
                    className='image-input-wrapper'
                    src={avatar.display ? avatar.display : templateForEdit.avatar}
                    alt='Image Alt Text'
                    style={{
                      width: '125px',
                      height: 'auto',
                      objectFit: 'contain',
                      borderRadius: '10px 10px 10px 10px',
                    }}
                  />
                  <a
                    onClick={() => {
                      downloadFile(avatar.display ? avatar.display : templateForEdit.avatar, dateDownloadFormat(templateForEdit.name))
                    }}
                    download={`${templateForEdit.name}.jpg`}
                    className='btn btn-icon btn-sm btn-bg-white btn-active-color-primary position-absolute bottom-0 end-0 m-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Download Image'
                  >
                    <i className='bi bi-download'></i>
                  </a>
                </>
              )}

              {/* end::Preview existing avatar */}

              {/* begin::Cancel */}
              {/* <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span> */}
              {/* end::Cancel */}

              {/* begin::Remove */}
              {/* <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                title='Remove avatar'
              >
                <i className='bi bi-x fs-2'></i>
              </span> */}
              {/* end::Remove */}
            </div>
            {/* end::Image input */}

            {/* begin::Hint */}
            {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
            {/* end::Hint */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Template Title</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Template Title'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.name && formik.errors.name },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isTemplateLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Date Created</label>
            {/* begin::Input */}
            <input
              placeholder='Date Created'
              aria-disabled
              {...formik.getFieldProps('dateCreated')}
              type='text'
              name='dateCreated'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.dateCreated && formik.errors.dateCreated },
                {
                  'is-valid': formik.touched.dateCreated && !formik.errors.dateCreated,
                }
              )}
              autoComplete='off'
              disabled={true}
            />
            {formik.touched.dateCreated && formik.errors.dateCreated && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.dateCreated}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}


          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Last Modified</label>
            {/* begin::Input */}
            <input
              placeholder='Last Moditied'
              aria-disabled
              {...formik.getFieldProps('updatedOn')}
              type='text'
              name='updatedOn'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.updatedOn && formik.errors.updatedOn },
                {
                  'is-valid': formik.touched.updatedOn && !formik.errors.updatedOn,
                }
              )}
              autoComplete='off'
              disabled={true}
            />
            {formik.touched.updatedOn && formik.errors.updatedOn && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.updatedOn}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}


          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Dimensions (w by h)</label>
            {/* begin::Input */}
            <input
              placeholder='Dimensions'
              aria-disabled
              {...formik.getFieldProps('dimensions')}
              type='text'
              name='dimensions'
              value={
                formik.values.dimensions
                  ? `${formik.values.dimensions.width} by ${formik.values.dimensions.height}`
                  : ''
              }
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.dimensions && formik.errors.dimensions },
                { 'is-valid': formik.touched.dimensions && !formik.errors.dimensions }
              )}
              autoComplete='off'
              disabled={true}
            />
            {formik.touched.dimensions && formik.errors.dimensions && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.dimensions}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Id</label>
            {/* begin::Input */}
            <input
              placeholder='Id'
              aria-disabled
              {...formik.getFieldProps('Id')}
              type='text'
              name='id'
              value={
                formik.values.id

              }
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.id && formik.errors.id },
                { 'is-valid': formik.touched.id && !formik.errors.id }
              )}
              autoComplete='off'
              disabled={true}
            />
            {formik.touched.id && formik.errors.id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.id}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Tags</label>
            {/* end::Label */}

            <div
              className='px-2'
              onClick={(event) => {
                event.stopPropagation();
              }}>

              <CreatableSelect
                // value={value}
                {...formik.getFieldProps('tags')}
                placeholder="Type to Choose or Create"
                hideSelectedOptions={false}
                onChange={(newValue) => {
                  formik.setFieldValue('tags', newValue, false)
                }}
                backspaceRemovesValue={false}
                blurInputOnSelect={false}
                className='text-uppercase fs-8'
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                  },
                })}
                isMulti={true}
                styles={{
                  option: (base, state) => ({
                    ...base,
                  }),
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      borderRadius: 5,
                      backgroundColor: '#432cffb8',
                      color: 'white',
                      boxShadow: '#0303030f 0px 0px 20px 0px'
                    };
                  },
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    fontWeight: 'bold',
                    color: 'white'

                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    ':hover': {
                      color: 'gray',
                    },
                  }),
                  control: (base) => ({
                    ...base,
                    // backgroundColor: var(--kt-input-solid-bg),
                    // zIndex: 9999,
                    padding: "5px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px'
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  menu: (base) => ({
                    ...base,
                    // zIndex: 19999,
                    backgroundColor: "white",
                    padding: "10px",
                    border: "none",
                    borderRadius: 10,
                    boxShadow: '#3d88a614 0px 0px 20px 0px'
                    // boxShadow: 'rgba(0, 0, 0, 0.07) -4px 9px 25px -6px'
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
                    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
                  }),
                  singleValue: (base) => ({ ...base, color: "#000", borderRadius: 10, }),
                }}
                // defaultValue={[dropdownOptions[2], dropdownOptions[3]]}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="color"
                options={dropdownOptions}
              />

              {formik.touched.tags && formik.errors.tags && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.tags}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Input */}
          </div>

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className=' fw-bold fs-6 mb-2'>Extra Info</label>
            {/* end::Label */}

            {/* begin::Input */}
            <textarea
              className='form-control form-control-solid mb-8'
              rows={3}
              placeholder='Add extra info here'
              {...formik.getFieldProps('info')}
              name='info'
              autoComplete='off'
              disabled={formik.isSubmitting || isTemplateLoading}
            ></textarea>
            {/* end::Input */}
          </div>

          <div className='mb-7'>
            <label className='required fw-bold fs-6 mb-5'>Hide on LUCY</label>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='disable'
                label='Yes'
                value={true}
                id='kt_modal_update_role_option_0'
                isTemplateLoading={isTemplateLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='disable'
                label='No'
                value={false}
                id='kt_modal_update_role_option_1'
                isTemplateLoading={isTemplateLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
          </div>

          <div className='mb-7'>
            <label className='required fw-bold fs-6 mb-5'>Type</label>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='type'
                label='Classic'
                value={'classic'}
                id='kt_modal_update_role_option_0'
                isTemplateLoading={isTemplateLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='type'
                label='Ai'
                value={'ai'}
                id='kt_modal_update_role_option_1'
                isTemplateLoading={isTemplateLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='type'
                label='Magic'
                value={'magic'}
                id='kt_modal_update_role_option_1'
                isTemplateLoading={isTemplateLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <RadioInput
                formik={formik}
                name='type'
                label='Sticker'
                value={'sticker'}
                id='kt_modal_update_role_option_1'
                isTemplateLoading={isTemplateLoading}
              />
            </div>
            <div className='separator separator-dashed my-5'></div>
          </div>

          <div className='mb-7 d-flex justify-content-between align-items-center'>
            <div className='fw-bold fs-6.25'>Storywall</div>
            <div className='form-check form-check-custom form-check-solid form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                id='storywallSwitch'
                checked={formik.values.microsite}
                onChange={() => handleMicrositeChange(!formik.values.microsite)}
                // onChange={() => formik.setFieldValue('microsite', !formik.values.microsite)}
                disabled={isTemplateLoading || formik.values.type === 'sticker'}
              />
            </div>
          </div>


          <div className='mb-7 d-flex justify-content-between align-items-center'>
            <div className='fw-bold fs-6.25'>DOOH</div>
            <div className='form-check form-check-custom form-check-solid form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                id='doohSwitch'
                checked={formik.values.dooh}
                onChange={() => handleDOOHChange(!formik.values.dooh)}
                // onChange={() => formik.setFieldValue('dooh', !formik.values.dooh)}
                disabled={isTemplateLoading}
              />
            </div>
          </div>


          <div className='mb-7 d-flex justify-content-between align-items-center'>
            <div className='fw-bold fs-6.25'>Print</div>
            <div className='form-check form-check-custom form-check-solid form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                id='printSwitch'
                checked={formik.values.print}
                onChange={() => formik.setFieldValue('print', !formik.values.print)}
                disabled={isTemplateLoading || formik.values.type === 'sticker'}
              />
            </div>
          </div>

          <div className='mb-7 d-flex justify-content-between align-items-center'>
            <div className='fw-bold fs-6.25'>Upload to Dashboard</div>
            <div className='form-check form-check-custom form-check-solid form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                id='uploadAllowedSwitch'
                checked={formik.values.uploadAllowed}
                onChange={() => handleUploadChange(!formik.values.uploadAllowed)}
                // onChange={() => formik.setFieldValue('uploadAllowed', !formik.values.uploadAllowed)}
                disabled={isTemplateLoading || formik.values.microsite || formik.values.dooh}
              />
            </div>
          </div>
          {/* begin::Input group */}
          <>
            {formik.values.type === 'ai' && (
              <>
                <label className="fw-bold fs-6 mb-5">Faces</label>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                    gap: '10px',
                    padding: '10px',
                  }}
                >
                  {/* Upload Area */}
                  <div
                    style={{
                      width: '150px',
                      height: '225px',
                      border: '2px dashed #eff2f9',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => document.getElementById('image-upload-input')?.click()}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      id="image-upload-input"
                      style={{ display: 'none' }}
                      onChange={handleImageUpload}
                    />
                    <AddPhotoAlternate style={{ fontSize: '36px', color: '#eff2f9' }} />
                  </div>

                  {/* Uploaded Images */}
                  {formik.values.faces?.map((face, index) => (
                    <ReorderableImage
                      key={index}
                      face={face}
                      index={index}
                      reorderImages={reorderImages}
                      removeImage={removeImage}
                      replaceImage={replaceImage}
                      setCropModalOpen={setCropModalOpen}
                      setCurrentImage={setCurrentImage}
                      setCurrentIndex={setCurrentIndex}
                    />
                  ))}
                </div>
              </>
            )}

            {/* Crop Modal */}
            {cropModalOpen && (
              <Modal
                open={cropModalOpen}
                onClose={() => setCropModalOpen(false)}
                closeAfterTransition
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90vw",
                    maxWidth: 800,
                    bgcolor: "background.paper",
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                    animation: "fadeIn 0.3s ease-in-out",
                    "@keyframes fadeIn": {
                      from: { opacity: 0 },
                      to: { opacity: 1 },
                    },
                  }}
                >
                  {currentImage && (
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: 400,
                        background: "#f0f0f0",
                        borderRadius: "8px",
                        overflow: "hidden",
                        border: "1px solid #ddd",
                      }}
                    >
                      <Cropper
                        image={currentImage}
                        crop={cropConfig}
                        zoom={zoom}
                        aspect={2 / 3}
                        onCropChange={setCropConfig}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                      />
                    </div>
                  )}
                  <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", width: "100%" }}>
                    <Tooltip title="Cancel and close the modal" arrow>
                      <button
                        style={{
                          backgroundColor: "white",
                          color: "#007bff",
                          padding: "10px 20px",
                          border: "1px solid #007bff",
                          borderRadius: "4px",
                          cursor: "pointer",
                          transition: "all 0.3s",
                        }}
                        onClick={() => setCropModalOpen(false)}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = "#007bff";
                          e.currentTarget.style.color = "white";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = "white";
                          e.currentTarget.style.color = "#007bff";
                        }}
                      >
                        Cancel
                      </button>
                    </Tooltip>
                    <Tooltip title="Confirm your selection" arrow>
                      <button
                        style={{
                          backgroundColor: "#007bff",
                          color: "white",
                          padding: "10px 20px",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                          transition: "background-color 0.3s",
                        }}
                        onClick={confirmCrop}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#0056b3")}
                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#007bff")}
                      >
                        Confirm
                      </button>
                    </Tooltip>
                  </div>
                </Box>
              </Modal>
            )}
          </>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-templates-modal-action='cancel'
            disabled={formik.isSubmitting || isTemplateLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-templates-modal-action='submit'
            disabled={isTemplateLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isTemplateLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form >
      {(formik.isSubmitting || isTemplateLoading) && <TemplatesListLoading />
      }
    </>
  )
}

export { TemplateEditModalForm };

